import React from 'react';

export default class ViewCart extends React.Component {
  render() {
    return <></>;
  }
componentDidMount() {

    const { props } = this.props;
    let items=[]
    // TODO:  add product cats

      props.products.forEach((item, i) => {
        //console.log(item)
        let product =  {
           "item_id": item.id,
           "item_name": item.name,
           "price": parseFloat(Number(item.currencyPrice.replace('€','')).toFixed(2)),
           "quantity": item.qty,
           "item_brand": "Shroom",
           "item_category": item.productCategories ? item.productCategories.nodes[0].name : '',
         }
         items.push(product)
      });


      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
         "event":  "view_cart",
         "ecommerce":  {
          "value": parseFloat(Number(props.totalProductsPriceEu.replace('€','')).toFixed(2)),
          "currency": "EUR",
          "items": items
        }
      });
  }
  
}
